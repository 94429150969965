import { Box, Button, Center, HStack, Icon, IconButton, Image, Link, Spacer, Text, VStack } from '@chakra-ui/react';
import { slsFetch } from '@klaudsol/commons/lib/Client';
import { USER_TOKEN } from 'lib/Constants';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BiSolidCircle } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi';
import { RiSearch2Line } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import ShoppingBagIcon from 'src/assets/icons/shopping-bag-white.svg';
import ThirstLogo from 'src/assets/icons/thirst-logo.png';
import { selectCart } from 'src/components/store/cart/cartSlice';
import { setCategoryProducts } from 'src/components/store/categories/categoriesSlice';
import { selectStoreDrawer, showDrawer } from 'src/components/store/storeDrawer/storeDrawerSlice';
import { selectUser } from 'src/components/store/user/userSlice';
import { useRootContext } from 'src/contexts/RootContext';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { getUserDetails } from 'src/modules/getUserDetails';
import verifyToken from 'src/modules/verifyToken';
import LocationMarkerIcon from 'src/assets/icons/location-marker.svg';
import SelectLocationIcon from 'src/assets/icons/select-location.svg';
export const DROPDOWN_PLACEHOLDER = 'Set delivery location';
import ReactGA from 'react-ga4';
import { showModal } from 'src/components/store/storeModal/storeModalSlice';

const StoreAppBar = ({ storeLocationVisible }) => {
  const rootContext = useRootContext();
  const _categories = rootContext?.categories ?? [];
  const { userDetails, isLoading: isUserDetailsLoading } = useAppSelector(selectUser);
  const { isOpen } = useAppSelector(selectStoreDrawer);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const { cartDetails, isLoading } = useAppSelector(selectCart);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [blueBannerText, setBlueBannerText] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCity, setSelectedCity] = useState(DROPDOWN_PLACEHOLDER);

  useEffect(() => {
    setBlueBannerText(localStorage.getItem('blue_banner_string'));
    isUserLoggedin();
  }, [isOpen]);

  useEffect(() => {
    const location = JSON.parse(localStorage.getItem('selectedLocation'));
    if (location && location.length != 0) {
      setSelectedCity(location.city);
    } else {
      setSelectedCity(DROPDOWN_PLACEHOLDER);
    }
  });

  const onSetLocationClick = () => {
    ReactGA.event({ category: 'Location', action: 'Location Selector Clicks' });
    if (cartDetails && cartDetails.total_items) {
      dispatch(showModal({ modalType: 'clear-cart-modal' }));
    } else {
      dispatch(showModal({ modalType: 'select-location-modal' }));
    }
  } 

  const onCartClick = () => {
    const cartId = localStorage.getItem('cartId');
    dispatch(setCategoryProducts(null));
    router.push({ pathname: '/store/cart/[cartId]', query: { cartId: cartId } });
  }

  const isUserLoggedin = async() =>  {
    const userData = await getUserDetails();
    setIsLoggedIn(userData?.length > 0)
  }

  return (
    <VStack
      placeSelf='center'
      position='fixed'
      top={0}
      left={0}
      w='100%'
      bg='white'
      spacing={0}
      zIndex={10}
    >
      <HStack
        w='100%'
        pl={['12px','22px']}
        pr={['12px','22px']}
        pt='20px'
        pb={2}
        spacing='10px'
        bg='white'
        boxShadow={8}
      >
        <Link 
          as={NextLink} 
          href='/' 
          textDecoration='none' 
          shallow={true} 
          prefetch={false}
          _focus={{ bg: 'white', textDecoration: 'none' }}
          _active={{ bg: 'white', textDecoration: 'none' }}
          _hover={{ bg: 'white', textDecoration: 'none' }}
        >
          <Image src={ThirstLogo.src} w={['126.27px','156.27px']} h={['50px','60px']} alt='thirst-logo' />
        </Link>
        
        <Box w='20px'/>
        {isDesktop && _categories.slice().sort((a, b) => (a.name).trim().localeCompare((b.name))).map((i, index) => {
          if (i.name.toLowerCase() == 'beer' || i.name.toLowerCase() == 'wine' || i.name.toLowerCase() == 'scotch whisky') {
            return (
              <Link 
                href={`/${i.slug}`}
                key={i.slug}
                fontWeight={500}
                fontFamily='CeraPro'
                textDecoration='none'
                fontSize='20px'
                lineHeight='24px'
                pt={3}
                pr={[0,0,'48px']}
                _focus={{ bg: 'white', textDecoration: 'none' }}
                _active={{ bg: 'white', textDecoration: 'none' }}
                _hover={{ bg: 'white', textDecoration: 'none' }}
              >
                {i.name}
              </Link>
            )
          }
        })};
          
        <Spacer />

        {storeLocationVisible && isDesktop && 
          <HStack 
            py={['16px','16px','16px','10px']}
            w={['90%','90%','90%','30%']}
            pr={5}
            placeSelf='center'
            bg='white'
            borderRadius={8}>
        <Center w='45px' h='45px' bg='#E9F2FA' borderRadius='8px'>
          <Image src={LocationMarkerIcon.src} w='18px' h='18px' objectFit='cover' alt='' />
        </Center>

        <VStack spacing={1} placeSelf='flex-start' pt={1} w='70%' pl={2} bg='white'>
          <Text 
            color='#98A6AD' 
            fontSize={['14px', '14px']} 
            fontFamily='CeraPro' 
            lineHeight='16px' 
            textAlign='left' 
            w='100%'
          >
            Delivering to
          </Text>
          <Text 
            fontWeight={500} 
            fontFamily='CeraPro' 
            lineHeight='16px' 
            fontSize={['16px', '16px']} 
            color='#214151' 
            placeSelf='start' 
            textAlign='left'
          >
            {selectedCity}
          </Text>
        </VStack>
        <Spacer />

        <IconButton
          icon={<Image src={SelectLocationIcon.src} w='15px' h='15px' objectFit='cover' alt='' />}
          onClick={onSetLocationClick}
          bg='#117BD4'
          w='30px'
          h='40px'
          aria-label='location-btn'
          _focus={{ border: 'none', bg: '#117BD4' }}
          _hover={{ border: 'none', bg: '#117BD4' }}
          _active={{ border: 'none', bg: '#117BD4' }}
        />
        </HStack>
        }

        {isLoggedIn && isDesktop &&
          <HStack pr={5}>
            <Icon as={BiSolidCircle} bg='white' color='#1BCA76' boxSize={4} />
            <Text fontFamily='CeraProLight' fontWeight={300} fontSize='14px'>Logged in</Text>
          </HStack>
        }

        {!isLoggedIn && isDesktop &&
          <HStack pr={5}>
            <Icon as={BiSolidCircle} bg='white' color='#98A6AD' boxSize={4} />
            <Text fontFamily='CeraProLight' fontWeight={300} fontSize='14px'>Logged out</Text>
          </HStack>
        }

        <Link 
          as={NextLink} 
          href="/store/search" 
          shallow={true} 
          prefetch={false}
          textDecoration='none' 
          _focus={{ bg: 'white', textDecoration: 'none' }}
          _active={{ bg: 'white', textDecoration: 'none' }}
          _hover={{ bg: 'white', textDecoration: 'none' }}
        >
          <Center
            w='50px'
            h='50px'
            bg='#E9F2FA'
            borderRadius={8}
          >
            <Icon as={RiSearch2Line} color='#117BD4' boxSize='22px' />
          </Center>
        </Link>

        <Button
          bg='#117BD4'
          borderRadius='7px'
          w='50px'
          h='50px'
          onClick={onCartClick}
          _focus={{ bg: '#117BD4', border: 'none' }}
          _active={{ bg: '#117BD4', border: 'none' }}
          _hover={{ bg: '#117BD4', border: 'none' }}
          isDisabled={!cartDetails || !cartDetails.line_items}
        >
          <HStack spacing='-9px' w='100%' h='100%'>
            <Image src={ShoppingBagIcon.src} w='22px' h='22px' objectFit='cover' alt='' />
            {cartDetails && cartDetails.total_items &&
              <Box zIndex={1} placeSelf='flex-start' pt='5px'>
                <Text
                  fontWeight={500}
                  fontFamily='CeraPro'
                  fontSize='10px'
                  color='white'
                  bg='#D43411'
                  borderRadius='100%'
                  px={cartDetails.total_items >= 9 ? '5px' : '7px'}
                  py={cartDetails.total_items >= 9 ? '4.5px' : '3px'}
                >
                  {cartDetails.total_items >= 9 ? '9+' : cartDetails.total_items}
                </Text>
              </Box>
            }
          </HStack>
        </Button>

        <IconButton
          icon={<Icon as={FiMenu} color='#117BD4' boxSize='22px' />}
          bg='#E9F2FA'
          w='50px'
          h='50px'
          aria-label='home-page-menu-btn'
          onClick={() => dispatch(showDrawer({ drawerType: 'drawer-home-page-menu' }))}
          isDisabled={isUserDetailsLoading}
          _focus={{ bg: '#E9F2FA', border: 'none' }}
          _active={{ bg: '#E9F2FA', border: 'none' }}
          _hover={{ bg: '#E9F2FA', border: 'none' }}
        />          
      </HStack>

      {storeLocationVisible && !isDesktop &&
          <HStack 
          py='10px'
          w='100%'
          px={4}
          placeSelf='center'
          bg='white'
          borderRadius={8}>
      <Center w='50px' h='50px' bg='#E9F2FA' borderRadius='8px'>
        <Image src={LocationMarkerIcon.src} w='22.5px' h='22.5px' objectFit='cover' alt='' />
      </Center>

      <VStack spacing={1} placeSelf='flex-start' pt={1} w='70%' pl={2} bg='white'>
        <Text 
          color='#98A6AD' 
          fontSize={['14px', '16px']} 
          fontFamily='CeraPro' 
          lineHeight='18px' 
          textAlign='left' 
          w='100%'
        >
          Delivering to
        </Text>
        <Text 
          fontWeight={500} 
          fontFamily='CeraPro' 
          lineHeight='18px' 
          fontSize={['16px', '18px']} 
          color='#214151' 
          placeSelf='start' 
          textAlign='left'
        >
          {selectedCity}
        </Text>
      </VStack>
      <Spacer />

      <IconButton
        icon={<Image src={SelectLocationIcon.src} w='22.5px' h='22.5px' objectFit='cover' alt='' />}
        onClick={onSetLocationClick}
        bg='#117BD4'
        w='54.5px'
        h='48.5px'
        aria-label='location-btn'
        _focus={{ border: 'none', bg: '#117BD4' }}
        _hover={{ border: 'none', bg: '#117BD4' }}
        _active={{ border: 'none', bg: '#117BD4' }}
      />
      </HStack>
      }

      {!isLoggedIn && !isDesktop &&
        <HStack placeSelf='flex-end' pr={4} pb={1.5} w='100%' bg='white'>
          <Spacer />
          <Icon as={BiSolidCircle} bg='white' color='#98A6AD' boxSize={3.5} />
          <Text fontFamily='CeraProLight' fontWeight={300} fontSize='12px'>Logged out</Text>
        </HStack>
      }
      {isLoggedIn && !isDesktop &&
        <HStack pr={4} pb={1.5} placeSelf='flex-end' w='100%' bg='white'>
          <Spacer />
          <Icon as={BiSolidCircle} bg='white' color='#1BCA76' boxSize={3} />
          <Text fontFamily='CeraProLight' fontWeight={300} fontSize='12px'>Logged in</Text>
        </HStack>
      }
      {blueBannerText != '' &&
        <Text 
          color='white'
          fontSize={['12px','14px']}
          fontFamily='CeraProLight'
          w='100%'
          lineHeight='14px'
          textAlign='center'
          bg='#117BD4'
          py='10px'
          px={[1,2]}
        >
          {blueBannerText}
        </Text>
      }
    </VStack>
  );
};

export default StoreAppBar;
