import { USER_TOKEN } from "lib/Constants";
import verifyToken from "./verifyToken";
import { slsFetch } from '@klaudsol/commons/lib/Client';

export const getUserDetails = async() => {
  const userToken = localStorage.getItem(USER_TOKEN);
  const decodedToken = verifyToken(userToken, USER_TOKEN);
  if (decodedToken) {
    let response = await slsFetch('/api/user/getUserDetails', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${userToken}` },
      body: JSON.stringify({ id: decodedToken.user }),
    });

    const { data } = await response.json();
    return data;
  }
}
